/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import debounce from "p-debounce";

import { AnyPushNotification, linkedStory, Target, PushNotificationUrlType } from "api/push-notification";
import { PartialAppState } from "../../state";
import AsyncSelect from "components/select/async";
import SubTitle from "components/icons/sub-title";
import Text from "components/icons/story-elements/text";
import TextArea from "components/text-area/text-area";
import { updateCurrentPushNotification } from "../../action-creators";
import { getPublishedStories } from "helpers/api";
import { getErrorMessage, hasError } from "../../utils";
import TextLimitIndicator from "components/text-limit-indicator/text-limit-indicator";
import styles from "./push-notification-form.module.css";
import classnames from "classnames/bind";
import { t } from "i18n";
import PushNotificationTargets from "pages/content/push-notification/components/push-notification-targets/push-notification-targets";
import RadioButton from "components/radio-button/radio-button";
import TextField from "components/text-field/text-field";
import { get } from "lodash";

interface StateProps {
  error: Error | null;
  fieldLimit: number;
  pushNotification: AnyPushNotification | null;
  channelsEnabled?: boolean;
}

interface DispatchProps {
  onChangeOfPushNotification: (change: AnyPushNotification) => void;
  getErrorMessage: (field: string, error: Error | null) => string;
  hasError: (field: string, error: Error | null) => boolean;
}

const cx = classnames.bind(styles);

const loadPublishedStories = debounce(
  (headline: string) => getPublishedStories({ headline, fields: "id,headline" }),
  250
);

type PushNotificationFormProps = StateProps & DispatchProps;

const PushNotificationForm: React.FC<PushNotificationFormProps> = ({
  pushNotification,
  onChangeOfPushNotification,
  error,
  fieldLimit,
  getErrorMessage,
  hasError,
  channelsEnabled
}) => {
  const targetPlatforms =
    pushNotification && pushNotification["target-categories"] ? pushNotification["target-categories"] : [];
  const onStoryChange = (newLinkedStory: linkedStory, pushNotification: AnyPushNotification) => {
    onChangeOfPushNotification({
      ...pushNotification,
      "story-content-id": newLinkedStory ? newLinkedStory["id"] : null,
      "linked-story": newLinkedStory
    });
  };

  return (
    <>
      {pushNotification && (
        <div className={styles["push-notification-container"]}>
          <section className={styles["push-notification-header-wrapper"]}>
            <div className={styles["push-notification-header-container"]}>
              <div className={styles["push-notification-header"]}>
                <div
                  className={cx(
                    "push-notification-field-label",
                    "title",
                    `${hasError("title", error) ? "has-error" : ""}`
                  )}>
                  <div className={`title-icon ${hasError("title", error) ? "has-error" : ""}`}>
                    <SubTitle color="currentColor" />
                    <TextLimitIndicator text={pushNotification.title || ""} limit={fieldLimit} />
                  </div>
                </div>

                <div className={styles["push-notification-title"]} data-test-id="push-notification-title">
                  <TextArea
                    onChange={(title) =>
                      onChangeOfPushNotification({
                        ...pushNotification,
                        title
                      })
                    }
                    placeholder={t("push-notification.form.title_hint")}
                    value={pushNotification.title}
                    errorMessage={getErrorMessage("title", error)}
                    variant="plain"
                    size="jumbo"
                  />
                </div>
                <div
                  className={cx(
                    "push-notification-field-label",
                    "message",
                    `${hasError("push-notification", error) ? "has-error" : ""}`
                  )}>
                  <div className={`title-icon ${hasError("push-notification", error) ? "has-error" : ""}`}>
                    <Text color="currentColor" />
                    <TextLimitIndicator text={pushNotification["push-notification"] || ""} limit={fieldLimit} />
                  </div>
                </div>
                <div className={styles["push-notification-message"]} data-test-id="push-notification-message">
                  <TextArea
                    onChange={(message) =>
                      onChangeOfPushNotification({
                        ...pushNotification,
                        "push-notification": message
                      })
                    }
                    placeholder={t("push-notification.form.message_hint")}
                    value={pushNotification["push-notification"]}
                    errorMessage={getErrorMessage("push-notification", error)}
                    variant="plain"
                    size="extra-large"
                  />
                </div>
                <div className={styles["also-read-preview-container"]}>
                  <PushNotificationTargets
                    targets={targetPlatforms}
                    channelsEnabled={channelsEnabled}
                    onUpdate={(targets: Array<Target>) =>
                      onChangeOfPushNotification({
                        ...pushNotification,
                        "target-categories": targets
                      })
                    }
                  />
                  {error && (
                    <div className={styles["push-notification-targets-error-message"]}>
                      {getErrorMessage("target-categories", error)}
                    </div>
                  )}
                  <div />
                  <div className={styles["push-notification-inspector-radio-group"]}>
                    <RadioButton
                      id="radio-no-story-type"
                      label={t("common.no-link")}
                      checked={pushNotification.metadata.type === PushNotificationUrlType.NO_LINK}
                      name="link story"
                      onChange={() => {
                        onChangeOfPushNotification({
                          ...pushNotification,
                          metadata: { type: PushNotificationUrlType.NO_LINK }
                        });
                      }}
                      classname={"push-notification-inspector-radio-button"} //change class names
                    />
                    <RadioButton
                      id="radio-internal-story-type"
                      label={t("common.link-to-story")}
                      checked={pushNotification.metadata.type === PushNotificationUrlType.STORY}
                      name="link story"
                      onChange={() => {
                        onChangeOfPushNotification({
                          ...pushNotification,
                          metadata: { type: PushNotificationUrlType.STORY }
                        });
                      }}
                      classname={"push-notification-inspector-radio-button"}
                    />
                    <RadioButton
                      id="radio-external-story-type"
                      label={t("common.link-to-custom-url")}
                      checked={pushNotification.metadata.type === PushNotificationUrlType.CUSTOM_URL}
                      name="link story"
                      onChange={() => {
                        onChangeOfPushNotification({
                          ...pushNotification,
                          metadata: { type: PushNotificationUrlType.CUSTOM_URL }
                        });
                      }}
                      classname={"push-notification-inspector-radio-button"}
                    />
                  </div>
                  <div />
                  {pushNotification.metadata.type === PushNotificationUrlType.STORY && (
                    <AsyncSelect
                      label={t("push-notification.form.linked_story_label")}
                      placeholder={t("story-editor.inspector.search")}
                      loadOptions={loadPublishedStories}
                      value={pushNotification["linked-story"]}
                      errorMessage={getErrorMessage("story-content-id", error)}
                      defaultOptions={false}
                      cacheOptions={null}
                      getOptionLabel={(story: linkedStory) => story.headline}
                      getOptionValue={(story: linkedStory) => story.id}
                      onChange={(newLinkedStory: linkedStory) => onStoryChange(newLinkedStory, pushNotification)}
                      isClearable={true}
                    />
                  )}
                  {pushNotification.metadata.type === PushNotificationUrlType.CUSTOM_URL && (
                    <TextField
                      label={t("common.link-to-custom-url")}
                      value={get(pushNotification, ["metadata", "external-url"])}
                      errorMessage={getErrorMessage("metadata", error)}
                      onChange={(external_url) => {
                        onChangeOfPushNotification({
                          ...pushNotification,
                          metadata: { ...pushNotification.metadata, "external-url": external_url }
                        });
                      }}
                    />
                  )}
                  <div />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    pushNotification: state.pushNotification.app.currentPushNotification,
    error: state.pushNotification.ui.details.error,
    channelsEnabled: state.features.enablePushNotificationChannels,
    fieldLimit: state.config.fieldLimits["push-notification"].limit || 140
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    onChangeOfPushNotification: (change) => dispatch(updateCurrentPushNotification(change)),
    getErrorMessage: (field, error) => getErrorMessage(field, error),
    hasError: (field, error) => hasError(field, error)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PushNotificationForm);

export { PushNotificationForm };
